define("creators-web-app/helpers/capitalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalize = capitalize;
  _exports.default = void 0;

  function capitalize(params, hash) {
    var str = params[0] || '';
    return Ember.String.capitalize(str);
  }

  var _default = Ember.Helper.helper(capitalize);

  _exports.default = _default;
});