define("creators-web-app/helpers/kind-from-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.kindFromFile = kindFromFile;
  _exports.default = void 0;

  function kindFromFile(params) {
    var fileType = params;

    if (fileType.indexOf('image') > -1) {
      return 'photo';
    } else if (fileType.indexOf('video') > -1) {
      return 'video';
    }
  }

  var _default = Ember.Helper.helper(kindFromFile);

  _exports.default = _default;
});