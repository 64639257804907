define("creators-web-app/lib/get-page-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    var titleElement = window.document.querySelectorAll('head title')[0];
    return titleElement && titleElement.innerText.trim().replace(/^\(\d+\/\d+\)/, '');
  }
});