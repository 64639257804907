define("creators-web-app/lib/get-url-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getUrlParams() {
    var search = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
    // get the current filters
    var currentParams = new URLSearchParams(search !== null && search !== void 0 ? search : window.location.search);
    var params = {}; // iterate on the parameters and save existing parameters into params object

    currentParams.forEach(function (value, param) {
      params[param] = Number.isNaN(Number(value)) ? value : parseInt(value, 10);
    });
    return params;
  }

  var _default = getUrlParams;
  _exports.default = _default;
});