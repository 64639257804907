define("creators-web-app/templates/protected/verified/gigs/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bHIUviPO",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"templates.protected.verified.gigs.index.page-title\"],null]],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"protected-verified-gigs-index\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"protected-verified-gigs-index__header\"],[12],[1,[28,[35,1],[\"templates.protected.verified.gigs.index.header\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"tabs\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"tabs__title\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"templates.protected.verified.gigs.index.tabs.title\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,0],[24,\"role\",\"button\"],[16,0,[29,[\"tabs__item \",[52,[28,[37,3],[[33,4],\"active\"],null],\"active\"]]]],[4,[38,5],[[30,0],\"showActiveGigs\"],null],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"templates.protected.verified.gigs.index.tabs.active\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,0],[24,\"role\",\"button\"],[16,0,[29,[\"tabs__item \",[52,[28,[37,3],[[33,4],\"inactive\"],null],\"active\"]]]],[4,[38,5],[[30,0],\"showPastGigs\"],null],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"templates.protected.verified.gigs.index.tabs.inactive\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[33,6,[\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"gig-cards\"],[12],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[33,6]],null]],null],null,[[[1,\"        \"],[8,[39,9],null,[[\"@gig\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,10],null,[[\"@titleTranslationPath\",\"@bodyTranslationPath\"],[\"templates.protected.verified.gigs.index.empty-list-title\",\"templates.protected.verified.gigs.index.empty-list-body\"]],null],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[8,[39,11],null,[[\"@infinityModel\",\"@hideOnInfinity\",\"@loadingText\"],[[36,12],true,\" \"]],null],[1,\"\\n\"],[13]],[\"gig\"],false,[\"page-title\",\"t\",\"if\",\"eq\",\"scope\",\"action\",\"gigs\",\"each\",\"-track-array\",\"gig-card\",\"empty-list\",\"infinity-loader\",\"model\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/gigs/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});