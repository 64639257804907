define("creators-web-app/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MyThtiF0",
    "block": "[[[10,0],[14,0,\"layout-unprotected\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"layout-unprotected__main\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"layout-unprotected__logo\"],[12],[1,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/assets/svg/logo-p-green.svg\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,0],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n\"],[13]],[],false,[\"not-found\",\"x-footer\"]]",
    "moduleName": "creators-web-app/templates/not-found.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});