define("creators-web-app/helpers/formatted-timestamp", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formattedTimestamp = formattedTimestamp;
  _exports.default = void 0;

  function _formatBasedOnTimeSince(timestamp, formatString) {
    var time = (0, _moment.default)(timestamp);
    var oneDayAgo = (0, _moment.default)().subtract(1, 'days');
    var oneWeekAgo = (0, _moment.default)().subtract(7, 'days');

    if (time > oneDayAgo) {
      return time.fromNow();
    } else if (time > oneWeekAgo) {
      return time.format('ddd h:mm A');
    }

    return time.format('MMM D');
  }

  function _formatBasedOnProvidedFormat(timestamp, formatString) {
    var time = (0, _moment.default)(timestamp);
    return time.format(formatString);
  }

  function formattedTimestamp(params) {
    var timestamp = params[0];
    var formatString = params[1];

    if (Ember.isBlank(timestamp)) {
      return '';
    }

    if (formatString) {
      return _formatBasedOnProvidedFormat(timestamp, formatString);
    }

    return _formatBasedOnTimeSince(timestamp);
  }

  var _default = Ember.Helper.helper(formattedTimestamp);

  _exports.default = _default;
});