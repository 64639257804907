define("creators-web-app/router", ["exports", "creators-web-app/config/environment", "creators-web-app/lib/react-messaging", "creators-web-app/lib/get-page-title"], function (_exports, _environment, _reactMessaging, _getPageTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    session: Ember.inject.service('session'),
    deltaEventHelper: Ember.inject.service('delta-event-helper'),
    didTransition: function didTransition() {
      var _this = this;

      this._super.apply(this, arguments);

      this.deltaEventHelper.screenPresented(this.currentRouteName, window.location.pathname);
      setTimeout(function () {
        if (_this.session.get('isAuthenticated')) {
          _reactMessaging.default.send('changeUrl', _this.currentURL);

          _reactMessaging.default.send('changePageTitle', (0, _getPageTitle.default)());
        }
      }, 0);
    },
    willTransition: function willTransition(oldInfos, newInfos, transition) {
      this._super.apply(this, arguments);

      var routeName = transition.from && transition.from.name;
      var destination = transition.to.name;
      this.deltaEventHelper.screenDismissed(routeName, destination);
    }
  });
  /*
  Routes tagged with `@public` are used by other parts of the system.
  They cannot be changed without leaving a redirect from the current path.
  Tagging a parent does NOT mean that all children are also public.
  */

  Router.map(function () {
    this.route('auth', function () {
      this.route('register', {
        path: '/register'
      });
      this.route('login', {
        path: '/login'
      }); // @public

      this.route('redirect-from-ltx');
    });
    this.route('protected', {
      path: '/'
    }, function () {
      this.route('verified', {
        path: '/'
      }, function () {
        this.route('account', function () {
          this.route('profile', function () {
            this.route('policy-update');
            this.route('anything', {
              path: '*anything'
            });
          });
          this.route('my-portfolio');
          this.route('banking', function () {
            this.route('show', {
              path: '/'
            });
            this.route('new', {
              path: '/new'
            });
          });
          this.route('shipping-address', function () {
            this.route('show', {
              path: '/'
            });
            this.route('new', {
              path: '/new'
            });
            this.route('edit', {
              path: '/edit/:shippingAddress_id'
            });
          });
          this.route('social-networks', function () {
            this.route('callback', {
              path: '/callback'
            });
          });
          this.route('notifications');
          this.route('manage-account');
        }); // nesting here is important for "active" class on sidebar

        this.route('campaigns', {
          path: '/'
        }, function () {
          this.route('networks', {
            path: '/'
          }, function () {
            this.route('index', {
              path: '/'
            });
            this.route('callback', {
              path: '/callback'
            });
            this.route('no-network', {
              path: '/content-creation'
            }); // @public where /:network is pinterest

            this.route('network', {
              path: '/:network'
            }, function () {
              this.route('campaigns', {
                path: '/'
              });
              this.route('callback', {
                path: '/callback'
              });
              this.route('connect', {
                path: '/connect'
              });
            });
          });
          this.route('campaign', {
            path: '/campaigns/:id'
          }, function () {
            this.route('apply');
            this.route('callback', {
              path: '/callback'
            });
          });
        }); // nesting here is important for "active" class on sidebar

        this.route('gigs', function () {
          this.route('index', {
            path: '/'
          });
          this.route('your-gigs', {
            path: '/your-gigs'
          }, function () {
            this.route('your-gigs', {
              path: '/:scope'
            });
          }); // @public

          this.route('gig', {
            path: '/:gigId'
          }, function () {
            this.route('activity', {
              path: '/'
            }, function () {
              this.route('index', {
                path: '/'
              });
              this.route('brief', {
                path: '/brief'
              });
              this.route('content-submissions', {
                path: '/:contentSubmissionKind'
              });
              this.route('proposal', {
                path: '/proposal'
              });
              this.route('posts', {
                path: '/posts'
              });
              this.route('reels', {
                path: '/reels'
              });
              this.route('stories', {
                path: '/stories'
              });
            });
            this.route('content-submission', {
              path: 'content-submission/:contentSubmissionId'
            }, function () {
              this.route('mixed-media');
              this.route('non-mixed-media');
            });
            this.route('prep', {
              path: '/prep'
            }, function () {
              // @public. Used in mailer and as iOS universal link
              this.route('shipping', {
                path: '/shipping'
              }); // @public. Used in mailer and as iOS universal link

              this.route('gift-codes', {
                path: '/gift-code'
              });
            });
          });
        }); // redirects to gig route with conversationId as a query param.
        // the slug is preferred by iOS universal links, but the param
        // matches the UI use better.
        // @public

        this.route('gig-conversation', {
          path: 'gigs/:gigId/conversations/:conversationId'
        });
        this.route('collaborations', {}, function () {
          this.route('collaboration', {
            path: '/:conversation_id'
          });
        });
      });
    }); // @public. Used by agency-web-app to live preview campaigns

    this.route('campaign-preview', {
      path: 'preview/campaign/:id'
    }); // @public. Used by campaign direct link to preview brief and apply.

    this.route('campaign-invite', {
      path: 'invite/campaign/:id'
    }); // this route catches everything that does not match the above routes.
    // Note: we have a wildcard /:network route which has a check to route
    // back to this route if it's not one of the supported networks.

    this.route('not-found', {
      path: '*not-found'
    });
  });
  var _default = Router;
  _exports.default = _default;
});