define("creators-web-app/templates/protected/verified/gigs/gig/content-submission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qu3y6sC7",
    "block": "[[[10,0],[14,0,\"protected-verified-gigs-gig-content-submission\"],[12],[1,\"\\n  \"],[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/gigs/gig/content-submission.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});