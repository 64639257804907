define("creators-web-app/templates/protected/verified/gigs/your-gigs/your-gigs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZFMUHYZd",
    "block": "[[[10,0],[14,0,\"your-gigs\"],[12],[1,\"\\n\"],[41,[33,1,[\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"paid-gig-cards\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[33,1]],null]],null],null,[[[1,\"        \"],[8,[39,4],[[24,0,\"paid-gig-cards__link\"]],[[\"@route\",\"@model\",\"@invokeAction\"],[\"protected.verified.gigs.gig\",[30,1,[\"id\"]],[28,[37,5],[[30,0],\"viewGig\",[30,1]],null]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,6],null,[[\"@paidGig\"],[[30,1]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[41,[33,7,[\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"collaborations-cards\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[33,7]],null]],null],null,[[[1,\"        \"],[8,[39,4],[[24,0,\"collaborations-cards__link\"]],[[\"@route\",\"@model\"],[\"protected.verified.campaigns.campaign\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,8],null,[[\"@collaboration\"],[[30,2]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,9],null,[[\"@titleTranslationPath\",\"@bodyTranslationPath\"],[\"templates.protected.verified.gigs.index.empty-list-title\",[30,0,[\"emptyStateBodyTranslationPath\"]]]],null],[1,\"\\n  \"]],[]]]],[]]],[1,\"\\n  \"],[8,[39,10],null,[[\"@infinityModel\",\"@hideOnInfinity\",\"@loadingText\"],[[36,11],true,\"\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"gig\",\"invite\"],false,[\"if\",\"gigs\",\"each\",\"-track-array\",\"link-to\",\"action\",\"paid-gig-card\",\"invites\",\"collaboration-card\",\"empty-list\",\"infinity-loader\",\"model\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/gigs/your-gigs/your-gigs.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});