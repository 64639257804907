define("creators-web-app/templates/protected/verified/gigs/gig/prep/shipping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iHcNfzGZ",
    "block": "[[[10,0],[14,0,\"protected-verified-gigs-gig-prep-shipping\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"components.gig-page.navigation.prep.shipping\"],null]]],null],[1,\"\\n  \"],[10,0],[14,0,\"protected-verified-gigs-gig-prep-shipping__status\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@type\",\"@status\"],[\"shipping\",[33,3,[\"shipment\",\"state\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[33,5],[[[1,\"        \"],[8,[39,6],null,[[\"@conversationId\",\"@trackClick\"],[[33,3,[\"brandConversation\",\"id\"]],[28,[37,7],[[30,0],\"trackClick\"],null]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,1],[[33,8]],[[\"htmlSafe\"],[true]]]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[33,3,[\"shipment\",\"trackingCode\"]],[[[1,\"    \"],[10,\"hr\"],[12],[13],[1,\"\\n    \"],[8,[39,9],null,[[\"@shipment\",\"@brand\"],[[33,3,[\"shipment\"]],[33,3,[\"campaign\",\"merchant\",\"name\"]]]],null],[1,\"\\n\"]],[]],null],[13]],[],false,[\"back-title-frame\",\"t\",\"status-with-icon\",\"model\",\"if\",\"isFulfilled\",\"deliverable-status/shipping-fulfilled\",\"action\",\"bodyTranslation\",\"tracking-info\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/gigs/gig/prep/shipping.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});