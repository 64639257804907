define("creators-web-app/templates/protected/verified/gigs/gig/activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fL8cPGgP",
    "block": "[[[10,0],[15,0,[28,[37,0],[\"protected-verified-gigs-gig-activity\",[52,[30,0,[\"displayNav\"]],\"\",\"--hide\"]],null]],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@gig\"],[[36,3]]],null],[1,\"\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"concat\",\"if\",\"gig-page/navigation\",\"model\",\"component\",\"-outlet\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/gigs/gig/activity.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});