define("creators-web-app/templates/protected/verified/campaigns/networks/network/campaigns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "trS8InoI",
    "block": "[[[1,[28,[35,0],[[33,1]],null]],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@class\",\"@campaigns\",\"@increaseEngagementBannerType\",\"@provider\",\"@message\"],[\"campaigns\",[36,3],[30,0,[\"currentUser\",\"increaseEngagementBannerType\"]],[30,0,[\"provider\"]],[30,0,[\"message\"]]]],null]],[],false,[\"page-title\",\"network\",\"campaign-list\",\"model\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/campaigns/networks/network/campaigns.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});