define("creators-web-app/templates/campaign-invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eF/9gUvk",
    "block": "[[[10,0],[15,0,[36,0]],[12],[1,\"\\n\\n  \"],[10,\"hr\"],[14,0,\"campaign-invite__nav-separator\"],[12],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"campaign-invite__main\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@campaign\",\"@showApplicationButton\",\"@showDismissButton\",\"@hideLocationBasedWarning\"],[[36,2],false,false,true]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[51,[33,4]],[[[1,\"    \"],[10,0],[14,0,\"campaign-invite__campaign-closed-overlay\"],[12],[1,\"\\n      \"],[10,\"h2\"],[14,0,\"campaign-invite__campaign-closed-title\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[\"campaign-invite.campaign-closed.title\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,2],[14,0,\"campaign-invite__campaign-closed-subtitle\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[\"campaign-invite.campaign-closed.subtitle\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[6,[39,6],null,[[\"class\",\"route\"],[\"campaign-invite__campaign-closed-auth\",\"protected\"]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,5],[\"campaign-invite.campaign-closed.authenticate-button\"],null]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"templateClassNames\",\"campaign-full\",\"model\",\"unless\",\"isActive\",\"t\",\"link-to\"]]",
    "moduleName": "creators-web-app/templates/campaign-invite.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});