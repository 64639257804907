define("creators-web-app/templates/protected/verified/campaigns/networks/no-network", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ffoezA+0",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"templates.protected.verified.campaigns.networks.no-network.page-title\"],null]],null]],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@campaigns\",\"@class\",\"@message\",\"@provider\"],[[36,3],\"campaigns no-network\",[30,0,[\"message\"]],[30,0,[\"provider\"]]]],null]],[],false,[\"page-title\",\"t\",\"campaign-list\",\"model\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/campaigns/networks/no-network.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});