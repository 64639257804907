define("creators-web-app/initializers/deprecations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    Ember.Debug.registerDeprecationHandler(function (message, options, next) {
      if (options.id === 'ember-application.injected-container') {
        return;
      }

      next(message, options);
    });
  }

  var _default = {
    name: 'deprecations',
    initialize: initialize
  };
  _exports.default = _default;
});