define("creators-web-app/helpers/string-concat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stringConcat = stringConcat;
  _exports.default = void 0;

  function stringConcat(params, hash) {
    var joinBy = hash.joinBy || '';

    if (Array.isArray(params[0])) {
      return params[0].join(joinBy);
    }

    return params.join(joinBy);
  }

  var _default = Ember.Helper.helper(stringConcat);

  _exports.default = _default;
});