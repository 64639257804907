define("creators-web-app/lib/pad-with-zeros", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var padWithZeros = function padWithZeros(value) {
    var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

    if (Ember.isEmpty(value)) {
      return '';
    }

    var paddedString = value + '';

    while (paddedString.length < size) {
      paddedString = '0' + paddedString;
    }

    return paddedString;
  };

  var _default = padWithZeros;
  _exports.default = _default;
});