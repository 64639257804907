define("creators-web-app/helpers/query-param-to-boolean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = queryParamToBoolean;

  function queryParamToBoolean(value) {
    return (value + '').toLowerCase() === 'true';
  }
});