define("creators-web-app/lib/media-kind-from-file", ["exports", "creators-web-app/lib/content-submission-validation-map"], function (_exports, _contentSubmissionValidationMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(fileType) {
    if (_contentSubmissionValidationMap.photoMap.fileTypes.includes(fileType)) {
      return 'photo';
    } else if (_contentSubmissionValidationMap.videoMap.fileTypes.includes(fileType)) {
      return 'video';
    } else if (_contentSubmissionValidationMap.blogMap.fileTypes.includes(fileType)) {
      return 'blog';
    }
  }
});