define("creators-web-app/mixins/non-mixed-media-uploadable", ["exports", "creators-web-app/lib/content-submission-validation-map"], function (_exports, _contentSubmissionValidationMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    allowedNonMixedMediaFileTypes: Ember.computed('kind', function () {
      switch (this.kind) {
        case 'photo':
          return _contentSubmissionValidationMap.default.photoMap.fileTypes;

        case 'video':
          return _contentSubmissionValidationMap.default.videoMap.fileTypes;

        case 'blog':
          return _contentSubmissionValidationMap.default.blogMap.fileTypes;

        default:
          return _contentSubmissionValidationMap.default.photoMap.fileTypes;
      }
    })
  });

  _exports.default = _default;
});