define("creators-web-app/helpers/number-format", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.numberFormat = numberFormat;
  _exports.default = void 0;

  function numberFormat(params, hash) {
    if (Ember.isEmpty(params[0])) {
      return '';
    }

    return (0, _numeral.default)(params[0]).format(params[1]);
  }

  var _default = Ember.Helper.helper(numberFormat);

  _exports.default = _default;
});