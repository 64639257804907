define("creators-web-app/lib/content-submission-validation-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.allMap = _exports.blogMap = _exports.videoMap = _exports.photoMap = _exports.mixedMediaMap = void 0;
  var GIGABYTE = 1073741824;
  var MEGABYTE = 1048576;
  var photoMap = {
    fileSize: 30 * MEGABYTE,
    fileTypes: ['image/gif', 'image/jpeg', 'image/png']
  };
  _exports.photoMap = photoMap;
  var videoMap = {
    fileSize: 3 * GIGABYTE,
    fileTypes: ['video/quicktime', 'video/mp4', 'video/x-m4v', 'video/x-ms-wmv', 'video/mpeg', 'video/mpg', 'video/x-msvideo', 'video/avi', 'video/x-flv', 'video/webm', 'application/x-shockwave-flash']
  };
  _exports.videoMap = videoMap;
  var blogMap = {
    fileSize: 30 * MEGABYTE,
    fileTypes: ['image/gif', 'image/jpeg', 'image/png', 'application/pdf']
  };
  _exports.blogMap = blogMap;
  var mixedMediaMap = {
    fileSizes: {
      photo: photoMap.fileSize,
      video: videoMap.fileSize,
      blog: blogMap.fileSize
    },
    fileTypes: [].concat(photoMap.fileTypes, videoMap.fileTypes, blogMap.fileTypes)
  };
  _exports.mixedMediaMap = mixedMediaMap;
  var allMap = {
    fileSize: 3 * GIGABYTE,
    fileTypes: ['image/gif', 'image/jpeg', 'image/png', 'application/pdf', 'image/gif', 'image/jpeg', 'image/png', 'video/quicktime', 'video/mp4', 'video/x-m4v', 'video/x-ms-wmv', 'video/mpeg', 'video/mpg', 'video/x-msvideo', 'video/avi', 'video/x-flv', 'video/webm', 'application/x-shockwave-flash']
  };
  _exports.allMap = allMap;
});