define("creators-web-app/components/pinterest-verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <SocialVerifyControl
    @network={{network}}
    @networkName={{@networkName}}
    @onAuthenticate={{action 'authenticate'}}
  />
  
  {{#if isWorking}}
    <PoppaysLoader />
  {{/if}}
  */
  {
    "id": "X0Ou8lZ7",
    "block": "[[[8,[39,0],null,[[\"@network\",\"@networkName\",\"@onAuthenticate\"],[[36,1],[30,1],[28,[37,2],[[30,0],\"authenticate\"],null]]],null],[1,\"\\n\\n\"],[41,[33,4],[[[1,\"  \"],[8,[39,5],null,null,null],[1,\"\\n\"]],[]],null]],[\"@networkName\"],false,[\"social-verify-control\",\"network\",\"action\",\"if\",\"isWorking\",\"poppays-loader\"]]",
    "moduleName": "creators-web-app/components/pinterest-verify.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});