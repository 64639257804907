define("creators-web-app/routes/application", ["exports", "creators-web-app/locales/en/translations", "ember-simple-auth/mixins/application-route-mixin", "creators-web-app/config/environment", "creators-web-app/lib/react-messaging"], function (_exports, _translations, _applicationRouteMixin, _environment, _reactMessaging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    intl: Ember.inject.service('intl'),
    router: Ember.inject.service('router'),
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service('session'),
    routeAfterAuthentication: 'protected.verified',
    transitioningToBack: false,
    blacklistedRouteRedirects: {
      'protected.verified.campaigns.campaign.apply': '/'
    },
    urlForTransitionHistory: Ember.computed('router.currentRouteName', function () {
      var router = this.router;
      var url = router.currentURL;
      var route = router.currentRouteName;
      var blacklistedUrl = this.blacklistedRouteRedirects[route];
      return blacklistedUrl || url;
    }),
    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments); // We force the Ember app to be redirected to the React app
      // This feature is disabled on dev/staging for automated testing


      if (!_reactMessaging.default.isHostedInReact && _environment.default.environment === 'production') {
        var domainHost = new URL(_environment.default.domainHost);
        var currentUrl = new URL(window.location.href);
        currentUrl.hostname = domainHost.hostname;
        currentUrl.port = domainHost.port;
        location.href = currentUrl.href;
      }

      _reactMessaging.default.listen('changeUrl', function (url) {
        _this.router.replaceWith("".concat(url.pathname).concat(url.search));
      });

      _reactMessaging.default.listen('logOut', function () {
        _this.session.invalidate();
      });

      this.intl.addTranslations('en-us', _translations.default);
      return this.intl.setLocale(['en-us']);
    },
    // When logging out keep query params
    sessionInvalidated: function sessionInvalidated() {
      if (!Ember.testing) {
        var redirectUrl = _environment.default.rootURL;
        var inviteCode = this.controllerFor('application').invite_code;
        var revision = this.controllerFor('application').revision;
        var queryParams = {};

        if (revision) {
          queryParams.revision = revision;
        }

        if (inviteCode) {
          queryParams.invite_code = inviteCode;
        }

        var query = $.param(queryParams);
        redirectUrl += "?".concat(query);
        window.location.replace(redirectUrl);
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        var url = this.urlForTransitionHistory;

        if (!this.transitioningToBack) {
          this.controller.transitionHistory.push(url);
        }

        Ember.set(this, 'transitioningToBack', false);
      },
      navigateBack: function navigateBack() {
        var transition = this.controller.transitionHistory.pop();
        Ember.set(this, 'transitioningToBack', true);
        this.transitionTo(transition || '/');
      },
      didTransition: function didTransition() {
        var msg = this.controller.get('toast_message');

        if (msg) {
          this.flashMessages.info(msg);
        }
      }
    }
  });

  _exports.default = _default;
});