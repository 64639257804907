define("creators-web-app/helpers/filesize-format", ["exports", "filesize"], function (_exports, _filesize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filesizeFormat = filesizeFormat;
  _exports.default = void 0;

  function filesizeFormat(params) {
    if (Ember.isEmpty(params[0])) {
      return '';
    }

    return (0, _filesize.default)(params[0]);
  }

  var _default = Ember.Helper.helper(filesizeFormat);

  _exports.default = _default;
});