define("creators-web-app/helpers/filetype-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filetypeFormat = filetypeFormat;
  _exports.default = void 0;

  function filetypeFormat(params) {
    if (Ember.isEmpty(params[0])) {
      return '';
    }

    var filename = params[0];
    return filename.split('.').pop();
  }

  var _default = Ember.Helper.helper(filetypeFormat);

  _exports.default = _default;
});