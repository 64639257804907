define("creators-web-app/components/poppays-headline", ["exports", "@popularpays/ember-design-system/components/poppays-headline"], function (_exports, _poppaysHeadline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _poppaysHeadline.default;
    }
  });
});