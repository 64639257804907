define("creators-web-app/templates/protected/verified/campaigns/networks/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S8mD7WrJ",
    "block": "[[[8,[39,0],null,[[\"@campaigns\",\"@class\",\"@increaseEngagementBannerType\",\"@message\",\"@provider\"],[[36,1],\"campaigns all\",[30,0,[\"currentUser\",\"increaseEngagementBannerType\"]],[30,0,[\"message\"]],[30,0,[\"provider\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"showRegistrationModal\"]],[[[1,\"  \"],[8,[39,3],null,[[\"@onClose\",\"@containerClassNames\"],[[28,[37,4],[[28,[37,5],[[30,0,[\"showRegistrationModal\"]]],null],false],null],\"ember-modal-dialog--white protected-verified-campaigns-networks-index__modal\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[12],[1,[28,[35,6],[\"templates.protected.verified.campaigns.networks.index.modal.title\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"subtitle\"],[12],[1,[28,[35,6],[\"templates.protected.verified.campaigns.networks.index.modal.subtitle\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"subtitle underlined email\"],[12],[1,[30,0,[\"currentUser\",\"email\"]]],[13],[1,\"\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n    \"],[10,2],[14,0,\"terms-and-conditions\"],[12],[1,\"\\n      \"],[1,[28,[35,6],[\"templates.protected.verified.campaigns.networks.index.modal.terms\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n    \"],[11,\"button\"],[24,0,\"button button--large\"],[4,[38,7],[\"click\",[28,[37,4],[[28,[37,5],[[30,0,[\"showRegistrationModal\"]]],null],false],null]],null],[12],[1,\"\\n      \"],[1,[28,[35,6],[\"templates.protected.verified.campaigns.networks.index.modal.button\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,8],null,[[\"@infinityModel\",\"@hideOnInfinity\",\"@loadingText\"],[[36,1],true,\" \"]],null]],[],false,[\"campaign-list\",\"model\",\"if\",\"modal-dialog\",\"fn\",\"mut\",\"t\",\"on\",\"infinity-loader\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/campaigns/networks/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});