define("creators-web-app/components/confetti-bomb", ["exports", "@popularpays/ember-design-system/components/confetti-bomb"], function (_exports, _confettiBomb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _confettiBomb.default;
    }
  });
});