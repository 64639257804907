define("creators-web-app/components/loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='loader-ring {{@className}}'>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  */
  {
    "id": "53K7MugS",
    "block": "[[[10,0],[15,0,[29,[\"loader-ring \",[30,1]]]],[12],[1,\"\\n  \"],[10,0],[12],[13],[1,\"\\n  \"],[10,0],[12],[13],[1,\"\\n  \"],[10,0],[12],[13],[1,\"\\n  \"],[10,0],[12],[13],[1,\"\\n\"],[13]],[\"@className\"],false,[]]",
    "moduleName": "creators-web-app/components/loader.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});