define("creators-web-app/templates/protected/verified/campaigns/networks/network/connect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xCQ+EJlI",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"templates.protected.verified.campaigns.networks.network.connect.page-title\"],[[\"network\"],[[33,2,[\"name\"]]]]]],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"protected-verified-campaigns-networks-network-connect\"],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"protected-verified-campaigns-networks-network-connect__icon protected-verified-campaigns-networks-network-connect__icon--\",[33,2,[\"id\"]]]]],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"protected-verified-campaigns-networks-network-connect__text\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"templates.protected.verified.campaigns.networks.network.connect.connect-text\"],[[\"name\"],[[33,2,[\"name\"]]]]]],[1,\"\\n    \"],[8,[39,3],null,[[\"@route\"],[\"protected.verified.account.social-networks\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,1],[\"templates.protected.verified.campaigns.networks.network.connect.connect-link\"],null]],[1,\".\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"t\",\"model\",\"link-to\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/campaigns/networks/network/connect.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});