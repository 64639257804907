define("creators-web-app/lib/delta-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DELTA_SCREEN_ROUTE_NAME_MAPPER = _exports.DELTA_SCREEN_IGNORE_ROUTES = _exports.flowNames = _exports.buttonNames = _exports.screenNames = _exports.deltaClientEvents = void 0;
  var deltaClientEvents = {
    generic: {
      button_pressed: 'button_pressed',
      screen_presented: 'screen_presented',
      screen_dismissed: 'screen_dismissed',
      flow_started: 'flow_started',
      flow_ended: 'flow_ended'
    }
  };
  _exports.deltaClientEvents = deltaClientEvents;
  var screenNames = {
    applications: {
      campaign: 'campaign'
    }
  };
  _exports.screenNames = screenNames;
  var buttonNames = {
    applications: {
      submit_my_application_for_review: 'submit_my_application_for_review'
    }
  };
  _exports.buttonNames = buttonNames;
  var flowNames = {
    applications: {
      apply_to_campaign: 'apply_to_campaign'
    }
  };
  _exports.flowNames = flowNames;
  var DELTA_SCREEN_IGNORE_ROUTES = [];
  _exports.DELTA_SCREEN_IGNORE_ROUTES = DELTA_SCREEN_IGNORE_ROUTES;
  var DELTA_SCREEN_ROUTE_NAME_MAPPER = {
    'protected.verified.campaigns.campaign.apply': {
      screen_name: screenNames.applications.campaign,
      tab: null
    }
  };
  _exports.DELTA_SCREEN_ROUTE_NAME_MAPPER = DELTA_SCREEN_ROUTE_NAME_MAPPER;
});