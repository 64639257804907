define("creators-web-app/lib/delegate-request-to-react", ["exports", "uuid/v4", "creators-web-app/lib/react-messaging"], function (_exports, _v, _reactMessaging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = delegateRequestToReact;

  function delegateRequestToReact(url, type, options, headers) {
    var requestId = (0, _v.default)();
    return new Ember.RSVP.Promise(function (res) {
      _reactMessaging.default.send('ajaxRequest', JSON.stringify({
        url: url,
        type: type,
        options: options,
        headers: headers,
        requestId: requestId
      }));

      _reactMessaging.default.listen("ajaxResponse:".concat(requestId), function (responseData) {
        var _JSON$parse = JSON.parse(responseData),
            data = _JSON$parse.data,
            status = _JSON$parse.status,
            headers = _JSON$parse.headers;

        res({
          data: data,
          status: status,
          headers: headers
        });
      });
    });
  }
});