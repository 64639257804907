define("creators-web-app/lib/react-messaging", ["exports", "creators-web-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ReactMessaging = function ReactMessaging() {
    _classCallCheck(this, ReactMessaging);
  };

  _exports.default = ReactMessaging;

  _defineProperty(ReactMessaging, "DOMAIN_HOST", _environment.default.domainHost);

  _defineProperty(ReactMessaging, "useDelegateRequestToReact", window.name === 'ember-iframe-cypress');

  _defineProperty(ReactMessaging, "isHostedInReact", window.name.includes('ember-iframe'));

  _defineProperty(ReactMessaging, "send", function (subject, message) {
    if (ReactMessaging.isHostedInReact) {
      window.parent.postMessage({
        subject: subject,
        message: message
      }, ReactMessaging.DOMAIN_HOST);
    }
  });

  _defineProperty(ReactMessaging, "listen", function (acceptedEventName, callback) {
    window.addEventListener('message', function (e) {
      var eventName = e.data.subject;
      var eventData = e.data.message;

      if (e.origin === ReactMessaging.DOMAIN_HOST && eventName === acceptedEventName) {
        callback(eventData);
      }
    }, false);
  });
});