define("creators-web-app/templates/protected/verified/gigs/gig/activity/proposal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DYQswEQz",
    "block": "[[[10,0],[14,0,\"protected-verified-gigs-gig-activity-proposal\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"components.gig-page.proposal.your-application\"],null]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@gig\"],[[36,3]]],null],[1,\"\\n\"],[13]],[],false,[\"back-title-frame\",\"t\",\"gig-page/proposal\",\"model\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/gigs/gig/activity/proposal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});