define("creators-web-app/components/card-footer/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='card-footer-item' data-test-card-footer-item={{@kind}}>
    <span
      class='card-footer-item__title {{@socialNetwork}} {{if @isBid "bid"}}'
    ><span class='social-network-icon'></span> {{@title}}</span>
    {{#if @isBid}}
      <span class='bid-icon'></span>
    {{/if}}
    <span class='card-footer-item__note'>{{@noteText}}</span>
  </div>
  */
  {
    "id": "fdCX4iVI",
    "block": "[[[10,0],[14,0,\"card-footer-item\"],[15,\"data-test-card-footer-item\",[30,1]],[12],[1,\"\\n  \"],[10,1],[15,0,[29,[\"card-footer-item__title \",[30,2],\" \",[52,[30,3],\"bid\"]]]],[12],[10,1],[14,0,\"social-network-icon\"],[12],[13],[1,\" \"],[1,[30,4]],[13],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[10,1],[14,0,\"bid-icon\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,1],[14,0,\"card-footer-item__note\"],[12],[1,[30,5]],[13],[1,\"\\n\"],[13]],[\"@kind\",\"@socialNetwork\",\"@isBid\",\"@title\",\"@noteText\"],false,[\"if\"]]",
    "moduleName": "creators-web-app/components/card-footer/item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});