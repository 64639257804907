define("creators-web-app/components/shipping-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LinkTo
    @route='protected.verified.account.shipping-address.edit'
    @model={{@address}}
    class='shipping-address'
  >
    <div class='shipping-address__label'>
      <span class='shipping-address__label__name'>{{@address.name}}</span>
      <span class='shipping-address__label__street'>{{@address.street1}}</span>
    </div>
    <span class='shipping-address__action-icon'>{{@actionIcon}}</span>
  </LinkTo>
  */
  {
    "id": "5UdpnbkQ",
    "block": "[[[8,[39,0],[[24,0,\"shipping-address\"]],[[\"@route\",\"@model\"],[\"protected.verified.account.shipping-address.edit\",[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"shipping-address__label\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"shipping-address__label__name\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n    \"],[10,1],[14,0,\"shipping-address__label__street\"],[12],[1,[30,1,[\"street1\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"shipping-address__action-icon\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[]]]]]],[\"@address\",\"@actionIcon\"],false,[\"link-to\"]]",
    "moduleName": "creators-web-app/components/shipping-address.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});