define("creators-web-app/models/user-campaign-list-item", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    blogsToUpload: (0, _model.attr)('number'),
    canApply: (0, _model.attr)('boolean'),
    fixedCost: (0, _model.attr)('number'),
    imageUrl: (0, _model.attr)('string'),
    merchantName: (0, _model.attr)('string'),
    mixedMediaToUpload: (0, _model.attr)('number'),
    name: (0, _model.attr)('string'),
    network: (0, _model.attr)('string'),
    objective: (0, _model.attr)('number'),
    objectiveProgress: (0, _model.attr)('number'),
    photosToUpload: (0, _model.attr)('number'),
    recruited: (0, _model.attr)('boolean'),
    requiredFeedPostsCount: (0, _model.attr)('number'),
    requiredReelsPostsCount: (0, _model.attr)('number'),
    requiredStoryPostsCount: (0, _model.attr)('number'),
    suggestedBidMax: (0, _model.attr)('number'),
    suggestedBidMin: (0, _model.attr)('number'),
    unlocksAt: (0, _model.attr)('number'),
    videosToUpload: (0, _model.attr)('number'),
    preferredCreatorProfile: (0, _model.belongsTo)('preferred-creator-profile'),
    socialNetwork: (0, _model.belongsTo)('social-network'),
    deliverables: (0, _model.hasMany)('deliverable'),
    blogUploadRequired: Ember.computed.gt('blogsToUpload', 0),
    mixedMediaUploadRequired: Ember.computed.gt('mixedMediaToUpload', 0),
    photoPostRequired: Ember.computed.gt('requiredFeedPostsCount', 0),
    photoUploadRequired: Ember.computed.gt('photosToUpload', 0),
    reelsPostRequired: Ember.computed.gt('requiredReelsPostsCount', 0),
    storyPostRequired: Ember.computed.gt('requiredStoryPostsCount', 0),
    videoUploadRequired: Ember.computed.gt('videosToUpload', 0),
    objectivePercent: Ember.computed('objective', 'objectiveProgress', function () {
      var _Ember$getProperties = Ember.getProperties(this, 'objective', 'objectiveProgress'),
          objective = _Ember$getProperties.objective,
          objectiveProgress = _Ember$getProperties.objectiveProgress;

      if (!objective || !objectiveProgress) {
        return 0;
      }

      return Math.floor(objectiveProgress / objective * 100);
    }),
    totalDeliverablesSum: Ember.computed('blogsToUpload', 'mixedMediaToUpload', 'photosToUpload', 'videosToUpload', 'requiredFeedPostsCount', 'requiredStoryPostsCount', function () {
      var _this = this;

      var props = ['blogsToUpload', 'mixedMediaToUpload', 'photosToUpload', 'videosToUpload', 'requiredFeedPostsCount', 'requiredStoryPostsCount'];
      return props.reduce(function (sum, prop) {
        return (_this.get(prop) || 0) + sum;
      }, 0);
    })
  });

  _exports.default = _default;
});