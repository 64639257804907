define("creators-web-app/templates/protected/verified/account/shipping-address/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SB/ogyOb",
    "block": "[[[8,[39,0],null,[[\"@submitText\",\"@onCancel\",\"@onSubmit\"],[\"Save Shipping Address\",[30,0,[\"onCancelShippingAddress\"]],[30,0,[\"addShippingAddress\"]]]],null]],[],false,[\"shipping-address-form\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/account/shipping-address/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});