define("creators-web-app/authenticators/poppays-from-token", ["exports", "creators-web-app/authenticators/poppays"], function (_exports, _poppays) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _poppays.default.extend({
    ajax: Ember.inject.service('ajax-with-headers'),
    authenticate: function authenticate(args) {
      var _args$authenticated,
          _args$authenticated2,
          _args$authenticated3,
          _this = this;

      return this.ajax.request('/user', {
        method: 'GET',
        headers: {
          'access-token': (_args$authenticated = args.authenticated) === null || _args$authenticated === void 0 ? void 0 : _args$authenticated.token,
          uid: (_args$authenticated2 = args.authenticated) === null || _args$authenticated2 === void 0 ? void 0 : _args$authenticated2.email,
          client: (_args$authenticated3 = args.authenticated) === null || _args$authenticated3 === void 0 ? void 0 : _args$authenticated3.client
        }
      }).then(function (response) {
        var _args$authenticated4, _args$authenticated5, _args$authenticated6;

        if (response.payload.data.attributes.status === 'suspended') {
          var error = new Error('User is suspended.');
          error.type = 'suspended';
          throw error;
        }

        _this.store.pushPayload('user', response.payload);

        return {
          accessToken: (_args$authenticated4 = args.authenticated) === null || _args$authenticated4 === void 0 ? void 0 : _args$authenticated4.token,
          uid: (_args$authenticated5 = args.authenticated) === null || _args$authenticated5 === void 0 ? void 0 : _args$authenticated5.email,
          client: (_args$authenticated6 = args.authenticated) === null || _args$authenticated6 === void 0 ? void 0 : _args$authenticated6.client
        };
      });
    }
  });

  _exports.default = _default;
});