define("creators-web-app/templates/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yrBN9WtR",
    "block": "[[[41,[28,[37,1],[[30,0,[\"isHostedInReact\"]]],null],[[[1,\"  \"],[10,0],[14,0,\"auth layout-unprotected\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"layout-unprotected__main\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"layout-unprotected__logo\"],[12],[1,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/assets/svg/logo-p-green.svg\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"auth__nav\"],[12],[1,\"\\n        \"],[6,[39,2],null,[[\"class\",\"route\"],[\"auth__nav-link\",\"auth.login\"]],[[\"default\"],[[[[1,[28,[35,3],[\"templates.auth.login-text\"],null]]],[]]]]],[1,\"\\n        \"],[6,[39,2],null,[[\"class\",\"route\"],[\"auth__nav-link\",\"auth.register\"]],[[\"default\"],[[[[1,[28,[35,3],[\"templates.auth.join-text\"],null]]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,6],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"not\",\"link-to\",\"t\",\"component\",\"-outlet\",\"x-footer\"]]",
    "moduleName": "creators-web-app/templates/auth.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});