define("creators-web-app/components/card-footer/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='card-footer paid-gig-card-body__body' data-test-card-footer>
    {{#each @items as |item last|}}
      <CardFooter::Item
        data-test-card-footer-item
        @kind={{item.kind}}
        @title={{item.title}}
        @noteText={{item.noteText}}
        @socialNetwork={{item.socialNetwork}}
        @isBid={{item.isBid}}
      />
      {{#unless (eq item @items.lastObject)}}
        <span class='card-footer__separator'></span>
      {{/unless}}
    {{/each}}
  </div>
  */
  {
    "id": "tdoxWZMc",
    "block": "[[[10,0],[14,0,\"card-footer paid-gig-card-body__body\"],[14,\"data-test-card-footer\",\"\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[8,[39,2],[[24,\"data-test-card-footer-item\",\"\"]],[[\"@kind\",\"@title\",\"@noteText\",\"@socialNetwork\",\"@isBid\"],[[30,2,[\"kind\"]],[30,2,[\"title\"]],[30,2,[\"noteText\"]],[30,2,[\"socialNetwork\"]],[30,2,[\"isBid\"]]]],null],[1,\"\\n\"],[41,[51,[28,[37,4],[[30,2],[30,1,[\"lastObject\"]]],null]],[[[1,\"      \"],[10,1],[14,0,\"card-footer__separator\"],[12],[13],[1,\"\\n\"]],[]],null]],[2,3]],null],[13]],[\"@items\",\"item\",\"last\"],false,[\"each\",\"-track-array\",\"card-footer/item\",\"unless\",\"eq\"]]",
    "moduleName": "creators-web-app/components/card-footer/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});