define("creators-web-app/lib/build-included-records", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var buildIncludedRecords = function buildIncludedRecords(modelRecords, typeOverride) {
    return modelRecords.map(function (model) {
      var json = model.serialize().data;
      json.id = model.get('id');
      json.type = typeOverride || Ember.String.underscore(json.type);
      return json;
    });
  };

  var _default = buildIncludedRecords;
  _exports.default = _default;
});