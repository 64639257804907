define("creators-web-app/validators/array-length", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // adapted from https://github.com/offirgolan/ember-cp-validations/issues/186#issuecomment-427705973
  var _default = _base.default.extend({
    intl: Ember.inject.service('intl'),
    validate: function validate(value, options, model, attribute) {
      // Must be array
      if (!Ember.isArray(value)) {
        return this.createErrorMessage('collection', value, options);
      } // Ensure correct length


      var length = Ember.get(value, 'length');
      var intl = this.intl;
      options = Object.assign({}, {
        description: 'This field'
      }, options);

      if (options.is && length !== options.is) {
        return intl.t('errors.arrayWrongLength', options);
      }

      if (options.min && length < options.min) {
        return intl.t('errors.arrayTooShort', options);
      }

      if (options.max && length > options.max) {
        return intl.t('errors.arrayTooLong', options);
      }

      return true;
    }
  });

  _exports.default = _default;
});