define("creators-web-app/components/state-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <TagWrapper @style={{@style}}>
    <div class='state-label' data-test-paid-gig-card-state-label>
      <div class='state-label__text'>
        {{@text}}
      </div>
    </div>
  </TagWrapper>
  */
  {
    "id": "xW4K3uro",
    "block": "[[[8,[39,0],null,[[\"@style\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"state-label\"],[14,\"data-test-paid-gig-card-state-label\",\"\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"state-label__text\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@style\",\"@text\"],false,[\"tag-wrapper\"]]",
    "moduleName": "creators-web-app/components/state-label.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});