define("creators-web-app/templates/protected/verified/account/profile/policy-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zaBUig2/",
    "block": "[[[8,[39,0],null,[[\"@showModal\",\"@onSubmit\"],[true,[28,[37,1],[[30,0],\"submit\"],null]]],null]],[],false,[\"policy-update\",\"action\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/account/profile/policy-update.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});