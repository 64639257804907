define("creators-web-app/helpers/moment-seconds-since", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.momentSecondsSince = momentSecondsSince;
  _exports.default = void 0;

  function momentSecondsSince(params) {
    var date = params[0];

    if (Ember.isEmpty(date)) {
      return '';
    }

    return (0, _moment.default)().diff(params[0], 'seconds');
  }

  var _default = Ember.Helper.helper(momentSecondsSince);

  _exports.default = _default;
});