define("creators-web-app/helpers/wait-for-element-to-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = waitForElementToDisplay;

  /**
   * Scrolls to the top of the given element
   * @param {string} [selector=.protected-verified__content-container] - Element selector
   * @param {string} [behavior=smooth] - Element selector
   */
  function waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
    var startTimeInMs = Date.now();

    (function loopSearch() {
      if (document.querySelector(selector) != null) {
        callback();
      } else {
        setTimeout(function () {
          if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) {
            return;
          }

          loopSearch();
        }, checkFrequencyInMs);
      }
    })();
  }
});