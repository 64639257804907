define("creators-web-app/templates/protected/verified/onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mOURfz0F",
    "block": "[[[8,[39,0],null,[[\"@networks\"],[[33,1,[\"networks\"]]]],null],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@campaigns\"],[[33,1,[\"campaigns\"]]]],null],[1,\"\\n\\n\"],[46,[28,[37,4],null,null],null,null,null]],[],false,[\"campaign-list-header\",\"model\",\"campaign-list\",\"component\",\"-outlet\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/onboarding.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});