define("creators-web-app/templates/protected/verified/account/shipping-address/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G1rrufz4",
    "block": "[[[8,[39,0],null,[[\"@submitText\",\"@onCancel\",\"@onDelete\",\"@onSubmit\",\"@showDelete\",\"@address\"],[\"Update Shipping Address\",[30,0,[\"onCancelShippingAddress\"]],[30,0,[\"onDeleteShippingAddress\"]],[30,0,[\"updateShippingAddress\"]],true,[30,1]]],null]],[\"@model\"],false,[\"shipping-address-form\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/account/shipping-address/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});