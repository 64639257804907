define("creators-web-app/templates/protected/verified/gigs/gig/activity/reels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZTi5DxyU",
    "block": "[[[10,0],[14,0,\"protected-verified-gigs-gig-activity-reels__manual-swap-match-container\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[34,0]],[13],[1,\"\\n  \"],[10,2],[12],[1,[28,[35,1],[\"templates.protected.verified.gigs.gig.activity.manual-swap-match.reels-sub-title\"],null]],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"button button--large button--blue\"],[4,[38,2],[[30,0],\"toggleModal\"],null],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"templates.protected.verified.gigs.gig.activity.manual-swap-match.add-reels-posts\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[33,4],[[[1,\"    \"],[8,[39,5],null,[[\"@onToggleModal\",\"@posts\",\"@socialNetwork\",\"@paidGigId\",\"@showWarning\",\"@postSocialStream\"],[[28,[37,2],[[30,0],\"toggleModal\"],null],[33,6,[\"reels\"]],[33,6,[\"paidGig\",\"socialNetwork\",\"id\"]],[33,6,[\"paidGig\",\"id\"]],true,\"reels\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"protected-verified-gigs-gig-activity-reels\"],[12],[1,\"\\n\"],[41,[33,6,[\"swapMatchReelsPosts\",\"length\"]],[[[42,[28,[37,8],[[28,[37,8],[[33,6,[\"swapMatchReelsPosts\"]]],null]],null],null,[[[1,\"        \"],[8,[39,9],null,[[\"@post\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null]],[]],[[[1,\"      \"],[8,[39,10],null,[[\"@type\",\"@status\"],[\"reels\",\"awaiting_reels\"]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"post\"],false,[\"swapMatchTitle\",\"t\",\"action\",\"if\",\"isShowingPostsModal\",\"post-selector-modal\",\"model\",\"each\",\"-track-array\",\"social-media-post\",\"status-with-icon\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/gigs/gig/activity/reels.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});