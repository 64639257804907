define("creators-web-app/constants/files", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.KIND_ATTRIBUTES = _exports.FILE_TYPES = _exports.GIGABYTE = _exports.MEGABYTE = void 0;
  var MEGABYTE = 1048576;
  _exports.MEGABYTE = MEGABYTE;
  var GIGABYTE = 1073741824;
  _exports.GIGABYTE = GIGABYTE;
  var FILE_TYPES = ['image/gif', 'image/jpeg', 'image/png', 'video/mp4', 'video/quicktime', 'video/x-m4v', 'video/x-ms-wmv', 'video/mpeg', 'video/mpg', 'video/x-msvideo', 'video/avi', 'video/x-flv', 'video/webm'];
  _exports.FILE_TYPES = FILE_TYPES;
  var KIND_ATTRIBUTES = {
    photo: {
      maxFileSize: 20 * MEGABYTE
    },
    video: {
      maxFileSize: 3 * GIGABYTE
    }
  };
  _exports.KIND_ATTRIBUTES = KIND_ATTRIBUTES;
});