define("creators-web-app/components/follower-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='follower-count'>
    <div class='follower-count__networks'>
      {{#each @networks as |network|}}
        {{#if
          (and
            (is-supported-network network.id)
            (not (is-empty network.identity.cachedFollowerCount))
          )
        }}
          <div
            class='follower-count__networks__network'
            data-test-follower-count-network={{network.id}}
          >
            <div
              class={{concat
                'follower-count__networks__network__icon icon--'
                network.id
              }}
            ></div>
            {{number-format network.identity.cachedFollowerCount '0.[0]a'}}
          </div>
        {{/if}}
      {{/each}}
    </div>
    <div class='follower-count__info'>
      <div class='icon--info-green'></div>
      <span>
        {{t 'components.follower-count.info' htmlSafe=true}}
      </span>
    </div>
  </div>
  */
  {
    "id": "EAT125li",
    "block": "[[[10,0],[14,0,\"follower-count\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"follower-count__networks\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[41,[28,[37,3],[[28,[37,4],[[30,2,[\"id\"]]],null],[28,[37,5],[[28,[37,6],[[30,2,[\"identity\",\"cachedFollowerCount\"]]],null]],null]],null],[[[1,\"        \"],[10,0],[14,0,\"follower-count__networks__network\"],[15,\"data-test-follower-count-network\",[30,2,[\"id\"]]],[12],[1,\"\\n          \"],[10,0],[15,0,[28,[37,7],[\"follower-count__networks__network__icon icon--\",[30,2,[\"id\"]]],null]],[12],[13],[1,\"\\n          \"],[1,[28,[35,8],[[30,2,[\"identity\",\"cachedFollowerCount\"]],\"0.[0]a\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[2]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"follower-count__info\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"icon--info-green\"],[12],[13],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      \"],[1,[28,[35,9],[\"components.follower-count.info\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@networks\",\"network\"],false,[\"each\",\"-track-array\",\"if\",\"and\",\"is-supported-network\",\"not\",\"is-empty\",\"concat\",\"number-format\",\"t\"]]",
    "moduleName": "creators-web-app/components/follower-count.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});