define("creators-web-app/components/before-after", ["exports", "ember-poppays/components/before-after"], function (_exports, _beforeAfter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _beforeAfter.default;
    }
  });
});