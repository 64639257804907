define("creators-web-app/components/card-frame/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='card-frame {{@class}}' data-test-card-frame={{@entity-id}}>
    {{yield}}
  </div>
  */
  {
    "id": "SgD1iF4Z",
    "block": "[[[10,0],[15,0,[29,[\"card-frame \",[30,1]]]],[15,\"data-test-card-frame\",[30,2]],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"@class\",\"@entity-id\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "creators-web-app/components/card-frame/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});