define("creators-web-app/transitions", ["exports", "creators-web-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // https://github.com/ember-animation/liquid-fire/issues/405
  function getDuration() {
    return _environment.default.environment === 'test' ? 0 : 250;
  }

  function _default() {
    this.transition(this.includingInitialRender(), this.fromValue(false), this.toValue(true), this.use('toLeft', {
      duration: getDuration()
    }), this.reverse('toRight', {
      duration: getDuration()
    }));
    this.transition(this.includingInitialRender(), this.fromRoute(null), this.toRoute('protected.verified.campaigns.campaign.apply'), this.use('toLeft', {
      duration: getDuration()
    }), this.reverse('toRight', {
      duration: getDuration()
    }));
  }
});