define("creators-web-app/validators/number", ["exports", "ember-cp-validations/validators/number"], function (_exports, _number) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _number.default;
    }
  });
});