define("creators-web-app/lib/paid-gig-state-map", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PENDING_TEXT_COLOR = '#3894FD';
  var ACTIVE_TEXT_COLOR = '#8000FF';
  var COMPLETED_TEXT_COLOR = '#07C98F';
  var ARCHIVE_TEXT_COLOR = '#757575';
  var PENDING_BACKGROUND_COLOR = '#F2F8FF';
  var ACTIVE_BACKGROUND_COLOR = '#F7EEFF';
  var COMPLETED_BACKGROUND_COLOR = '#F5FFFC';
  var ARCHIVE_BACKGROUND_COLOR = '#F1F1F1';
  var paidGigStateMap = {
    proposed: {
      bodyComponentName: 'gig-card/proposed-body',
      badgeFileName: '/assets/svg/badges/status-waiting.svg',
      badgeLabelFileName: '/assets/svg/badges/status-waiting-sm.svg',
      stateLabel: 'Proposed',
      hideCompletedAmount: true,
      stateLabelText: function stateLabelText(_paidGig) {
        return 'Proposed';
      },
      stateLabelTextColor: PENDING_TEXT_COLOR,
      stateLabelBackgroundColor: PENDING_BACKGROUND_COLOR
    },
    awaiting_preapproval: {
      bodyComponentName: 'gig-card/proposed-body',
      badgeFileName: '/assets/svg/badges/status-waiting.svg',
      badgeLabelFileName: '/assets/svg/badges/status-waiting-sm.svg',
      stateLabel: 'Proposed',
      hideCompletedAmount: true,
      stateLabelText: function stateLabelText(_paidGig) {
        return 'Proposed';
      },
      stateLabelTextColor: PENDING_TEXT_COLOR,
      stateLabelBackgroundColor: PENDING_BACKGROUND_COLOR
    },
    rejected: {
      bodyComponentName: 'gig-card/rejected-body',
      badgeFileName: '/assets/svg/badges/status-rejected.svg',
      badgeLabelFileName: '/assets/svg/badges/status-rejected-sm.svg',
      stateLabel: 'Brand declined',
      hideCompletedAmount: true,
      stateLabelText: function stateLabelText(_paidGig) {
        return 'Brand declined';
      },
      stateLabelTextColor: ARCHIVE_TEXT_COLOR,
      stateLabelBackgroundColor: ARCHIVE_BACKGROUND_COLOR
    },
    expired: {
      bodyComponentName: 'gig-card/expired-body',
      badgeFileName: '/assets/svg/badges/status-rejected.svg',
      badgeLabelFileName: '/assets/svg/badges/status-rejected-sm.svg',
      stateLabel: 'Gig no longer active',
      hideCompletedAmount: true,
      stateLabelText: function stateLabelText(_paidGig) {
        return 'Expired';
      },
      stateLabelTextColor: ARCHIVE_TEXT_COLOR,
      stateLabelBackgroundColor: ARCHIVE_BACKGROUND_COLOR
    },
    cancelled: {
      bodyComponentName: 'gig-card/cancelled-body',
      badgeFileName: '/assets/svg/badges/status-rejected.svg',
      badgeLabelFileName: '/assets/svg/badges/status-rejected-sm.svg',
      stateLabel: 'Gig was cancelled',
      hideCompletedAmount: true,
      stateLabelText: function stateLabelText(_paidGig) {
        return 'Cancelled';
      },
      stateLabelTextColor: ARCHIVE_TEXT_COLOR,
      stateLabelBackgroundColor: ARCHIVE_BACKGROUND_COLOR
    },
    awaiting_influencer_uploads: {
      bodyComponentName: 'gig-card/awaiting-influencer-body',
      badgeFileName: '/assets/svg/badges/status-in-progress.svg',
      badgeLabelFileName: '/assets/svg/badges/status-in-progress-sm.svg',
      stateLabel: 'Submit your content',
      hideCompletedAmount: false,
      stateLabelText: function stateLabelText(_paidGig) {
        return 'Submit your content';
      },
      stateLabelTextColor: ACTIVE_TEXT_COLOR,
      stateLabelBackgroundColor: ACTIVE_BACKGROUND_COLOR
    },
    awaiting_influencer_posts: {
      bodyComponentName: 'gig-card/awaiting-influencer-body',
      badgeFileName: '/assets/svg/badges/status-in-progress.svg',
      badgeLabelFileName: '/assets/svg/badges/status-in-progress-sm.svg',
      stateLabel: 'Post your content',
      hideCompletedAmount: false,
      stateLabelText: function stateLabelText(_paidGig) {
        return 'Post your content';
      },
      stateLabelTextColor: ACTIVE_TEXT_COLOR,
      stateLabelBackgroundColor: ACTIVE_BACKGROUND_COLOR
    },
    awaiting_brand_uploads_feedback: {
      bodyComponentName: 'gig-card/awaiting-brand-body',
      badgeFileName: '/assets/svg/badges/status-waiting.svg',
      badgeLabelFileName: '/assets/svg/badges/status-waiting-sm.svg',
      stateLabel: 'Brand reviewing content',
      hideCompletedAmount: false,
      stateLabelText: function stateLabelText(_paidGig) {
        return 'Brand reviewing content';
      },
      stateLabelTextColor: ACTIVE_TEXT_COLOR,
      stateLabelBackgroundColor: ACTIVE_BACKGROUND_COLOR
    },
    awaiting_payment: {
      bodyComponentName: 'gig-card/awaiting-payment-body',
      badgeFileName: '/assets/svg/badges/status-waiting-payment.svg',
      badgeLabelFileName: '/assets/svg/badges/status-waiting-payment-sm.svg',
      stateLabel: 'Payment Date:',
      hideCompletedAmount: false,
      stateLabelText: function stateLabelText(paidGig) {
        return "Payment Date: ".concat((0, _moment.default)(paidGig.expectedPaymentDate).format('MM/DD/YYYY'));
      },
      stateLabelTextColor: ACTIVE_TEXT_COLOR,
      stateLabelBackgroundColor: ACTIVE_BACKGROUND_COLOR
    },
    completed: {
      bodyComponentName: 'gig-card/completed-body',
      badgeFileName: '',
      badgeLabelFileName: '/assets/svg/badges/status-completed-sm.svg',
      stateLabel: "Gig's completed!",
      hideCompletedAmount: false,
      stateLabelText: function stateLabelText(_paidGig) {
        return 'Completed';
      },
      stateLabelTextColor: COMPLETED_TEXT_COLOR,
      stateLabelBackgroundColor: COMPLETED_BACKGROUND_COLOR
    }
  };
  var _default = paidGigStateMap;
  _exports.default = _default;
});