define("creators-web-app/templates/protected/verified/account/banking/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "23/if2UD",
    "block": "[[[41,[33,1],[[[1,\"  \"],[8,[39,2],[[24,0,\"icon-link icon-link--add\"]],[[\"@route\"],[\"protected.verified.account.banking.new\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,3],[\"templates.protected.verified.account.banking.show.add-payment-method\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,4],null,[[\"@gateway\",\"@accountLastDigits\",\"@email\",\"@onDeleteGateway\"],[[33,5,[\"paymentAccount\",\"gateway\"]],[33,5,[\"bankAccount\",\"lastDigits\"]],[33,5,[\"paymentAccount\",\"email\"]],[28,[37,6],[[30,0],\"deleteGateway\"],null]]],null],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"noPaymentAccount\",\"link-to\",\"t\",\"user-bank-account-display\",\"model\",\"action\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/account/banking/show.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});