define("creators-web-app/templates/protected/verified/campaigns/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bpxgp1/T",
    "block": "[[[8,[39,0],null,null,null]],[],false,[\"poppays-loader\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/campaigns/loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});