define("creators-web-app/templates/protected/verified/campaigns/campaign/apply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jJoPuCoS",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"templates.protected.verified.campaigns.campaign.apply.page-title\"],null]],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"campaign-apply__overlay\"],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@onClickClose\"],[[28,[37,3],[[30,0],\"hideGigForm\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@campaign\",\"@profile\",\"@existingShippingAddresses\",\"@onSaveAddress\",\"@onSubmit\"],[[33,5,[\"campaign\"]],[33,5,[\"profile\"]],[36,6],[28,[37,3],[[30,0],\"saveNewAddress\"],null],[28,[37,3],[[30,0],\"saveGigApplication\"],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"t\",\"content-overlay\",\"action\",\"gig-form\",\"model\",\"existingShippingAddresses\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/campaigns/campaign/apply.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});