define("creators-web-app/authenticators/poppays", ["exports", "ember-simple-auth/authenticators/base", "ember-ajax/errors", "js-sha512"], function (_exports, _base, _errors, _jsSha) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    ajax: Ember.inject.service('ajax-with-headers'),
    store: Ember.inject.service('store'),
    reporting: Ember.inject.service('raven'),
    restore: function restore(data) {
      if (data['accessToken']) {
        return Ember.RSVP.resolve(data);
      }

      return Ember.RSVP.reject();
    },
    authenticate: function authenticate(email, password) {
      var _this = this;

      return this.ajax.request('/auth/sign_in', {
        method: 'POST',
        data: {
          email: email,
          password: (0, _jsSha.default)(password)
        }
      }).then(function (response) {
        if (response.payload.data.attributes.status === 'suspended') {
          var error = new Error('User is suspended.');
          error.type = 'suspended';
          throw error;
        }

        _this.store.pushPayload('user', response.payload);

        if (!response.headers.accessToken) {
          throw new Error('Auth response did not contain `access-token` or `Access-Token` header');
        }

        return {
          accessToken: response.headers.accessToken,
          uid: response.headers.uid,
          client: response.headers.client
        };
      });
    },
    invalidate: function invalidate(data) {
      var _this2 = this;

      localStorage.clear();
      return this.ajax.request('/auth/sign_out').catch(function (error) {
        // If a user has a stale accessToken, this call
        // will result in a 404 Not Found response.
        // This is expected behavior so we can fail silently.
        if ((0, _errors.isNotFoundError)(error)) {
          return;
        }

        _this2.reporting.notify(error, 'The API responded with an error when attempting to logout the user');
      });
    }
  });

  _exports.default = _default;
});