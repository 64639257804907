define("creators-web-app/helpers/scroll-to-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scrollToTop;

  /**
   * Scrolls to the top of the given element
   * @param {string} [selector=.protected-verified__content-container] - Element selector
   * @param {string} [behavior=smooth] - Element selector
   */
  function scrollToTop() {
    var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.protected-verified__content-container';
    var behavior = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'smooth';
    var el = document.querySelector(selector);

    if (el) {
      if (el.scroll) {
        el.scroll({
          top: 0,
          behavior: behavior
        });
      } else {
        el.scrollTop = 0;
      }
    }
  }
});