define("creators-web-app/templates/protected/verified/gigs/gig/prep/gift-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nRWZm7VJ",
    "block": "[[[10,0],[14,0,\"protected-verified-gigs-gig-prep-gift-codes\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"components.gig-page.navigation.prep.gift-codes\"],null]]],null],[1,\"\\n  \"],[10,0],[14,0,\"protected-verified-gigs-gig-prep-gift-codes__status\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@type\",\"@status\"],[\"gift-code\",[33,3,[\"giftCode\",\"state\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[33,5],[[[1,\"        \"],[8,[39,6],null,[[\"@conversationId\",\"@trackClick\"],[[33,3,[\"brandConversation\",\"id\"]],[28,[37,7],[[30,0],\"trackClick\"],null]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,1],[[33,8]],null]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[33,3,[\"giftCode\",\"couponCode\"]],[[[1,\"    \"],[10,0],[14,0,\"protected-verified-gigs-gig-prep-gift-codes__coupon-code\"],[12],[1,\"\\n      \"],[8,[39,9],null,[[\"@couponCode\"],[[33,3,[\"giftCode\",\"couponCode\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"back-title-frame\",\"t\",\"status-with-icon\",\"model\",\"if\",\"isFulfilled\",\"deliverable-status/gift-code-fulfilled\",\"action\",\"bodyTranslation\",\"gift-code\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/gigs/gig/prep/gift-codes.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});