define("creators-web-app/templates/protected/verified/account/shipping-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q2YNwXn6",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"templates.protected.verified.account.shipping-address.page-title\"],null]],null]],[1,\"\\n\"],[10,0],[14,0,\"protected-verified-account__outlet\"],[12],[1,\"\\n\"],[41,[30,0,[\"isMobile\",\"any\"]],[[[1,\"    \"],[8,[39,3],[[4,[38,5],[\"click\",[30,0,[\"onClickBackProfile\"]]],null]],[[\"@model\",\"@title\",\"@linkToRedirect\",\"@showBackButton\"],[[33,4,[\"profile\",\"profileProgressReport\"]],[29,[[28,[37,1],[\"templates.protected.verified.account.shipping-address.page-title\"],null]]],\"protected.verified.account\",true]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"account-outlet-container\"],[12],[1,\"\\n\\n    \"],[8,[39,6],null,[[\"@title\",\"@description\"],[[29,[[52,[51,[30,0,[\"isMobile\",\"any\"]]],[28,[37,1],[\"templates.protected.verified.account.shipping-address.page-title\"],null]]]],[28,[37,1],[\"templates.protected.verified.account.shipping-address.page-description\"],null]]],null],[1,\"\\n    \"],[46,[28,[37,9],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"t\",\"if\",\"account-header\",\"model\",\"on\",\"section-header\",\"unless\",\"component\",\"-outlet\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/account/shipping-address.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});