define("creators-web-app/templates/protected/verified/gigs/your-gigs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BA+mw2Hb",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"templates.protected.verified.gigs.index.page-title\"],null]],null]],[1,\"\\n\"],[11,0],[4,[38,2],[[30,0,[\"initializeGigCount\"]]],null],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"protected-verified-gigs-index\"],[12],[1,\"\\n\\n  \"],[10,0],[14,0,\"protected-verified-gigs-index__header_and_navbar\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"protected-verified-gigs-index__header\"],[12],[1,[28,[35,1],[\"templates.protected.verified.gigs.index.header\"],null]],[13],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@gigsCount\"],[[36,4]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"t\",\"did-insert\",\"your-gigs-navbar\",\"gigsCount\",\"component\",\"-outlet\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/gigs/your-gigs.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});