define("creators-web-app/helpers/string-truncate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stringTruncate = stringTruncate;
  _exports.default = void 0;

  function stringTruncate(params, lengthToDisplay) {
    if (Ember.isEmpty(params[0])) {
      return '';
    }

    var str = params[0];
    return str.length > lengthToDisplay ? str.slice(0, lengthToDisplay - 1) + '…' : str;
  }

  var _default = Ember.Helper.helper(stringTruncate);

  _exports.default = _default;
});