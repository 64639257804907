define("creators-web-app/components/collaboration-card/gig-for-you", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='collaboration-card__recruited'>
    <div
      class='collaboration-card__recruited-img'
      data-test-collaboration-card-recruited={{@collaboration.id}}
    ></div>
    <div class='collaboration-card__recruited-text'>
      {{t 'components.collaboration-card.recruited-text'}}
    </div>
  </div>
  */
  {
    "id": "MZbBKMOW",
    "block": "[[[10,0],[14,0,\"collaboration-card__recruited\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"collaboration-card__recruited-img\"],[15,\"data-test-collaboration-card-recruited\",[30,1,[\"id\"]]],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"collaboration-card__recruited-text\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"components.collaboration-card.recruited-text\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@collaboration\"],false,[\"t\"]]",
    "moduleName": "creators-web-app/components/collaboration-card/gig-for-you.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});