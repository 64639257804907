define("creators-web-app/lib/parse-error-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var message = "Don't sweat, it's not your fault, but that didn't work. Try again?";

  var parseErrorMessage = function parseErrorMessage(errorClass) {
    var defaultMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : message;
    var excludedErrors = Ember.A(['Adapter Error']);
    var payload = errorClass.payload;
    var errorDetail = Ember.get(payload, 'errors.firstObject.detail');
    var errorTitle = Ember.get(payload, 'errors.firstObject.title');

    if (errorDetail && !excludedErrors.includes(errorTitle)) {
      return errorDetail;
    }

    return defaultMessage;
  };

  var _default = parseErrorMessage;
  _exports.default = _default;
});