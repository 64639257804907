define("creators-web-app/templates/protected/verified/account/shipping-address/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UoZ3Px0b",
    "block": "[[[10,\"ul\"],[14,0,\"protected-verified-account-shipping-address-show__list\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"addresses\"]]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[12],[8,[39,2],null,[[\"@address\",\"@actionIcon\"],[[30,1],\">\"]],null],[13],[1,\"\\n    \"],[10,\"hr\"],[12],[13],[1,\"\\n\"]],[1]],[[[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"templates.protected.verified.account.shipping-address.show.no-saved-addresses\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"],[8,[39,4],[[24,0,\"icon-link icon-link--add\"]],[[\"@route\"],[\"protected.verified.account.shipping-address.new\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,3],[\"templates.protected.verified.account.shipping-address.show.add-shipping-address\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"address\"],false,[\"each\",\"-track-array\",\"shipping-address\",\"t\",\"link-to\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/account/shipping-address/show.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});