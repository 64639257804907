define("creators-web-app/templates/protected/verified/collaborations/collaboration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UiZEmpFa",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"templates.protected.verified.collaborations.page-title\"],null]],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"protected-collaborations-collaboration\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"protected-collaborations-collaboration__header\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"protected-collaborations-collaboration_header-close-btn\"],[4,[38,2],[[30,0],\"closeFrame\"],null],[12],[1,\"x\"],[13],[1,\"\\n      \"],[1,[28,[35,1],[\"templates.protected.verified.collaborations.collaboration.collaboration-title\"],[[\"displayName\",\"htmlSafe\"],[[33,3,[\"conversation\",\"merchant\",\"name\"]],true]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"protected-collaborations-collaboration__body\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"protected-collaborations-collaboration__body-content\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"protected-collaborations-collaboration__messages\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@messages\",\"@firstUnreadMessageId\",\"@conversation\",\"@messageMarkedUnread\",\"@scrollToBottom\"],[[33,3,[\"messages\"]],[36,5],[33,3,[\"conversation\"]],[28,[37,2],[[30,0],\"messageMarkedUnread\"],null],[28,[37,2],[[30,0],\"scrollToBottom\"],null]]],null],[1,\"\\n        \"],[8,[39,6],null,[[\"@onCreateMessage\",\"@allowAttachments\",\"@context\"],[[28,[37,2],[[30,0],\"createMessage\"],null],true,\"Collaboration\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"t\",\"action\",\"model\",\"messages-list\",\"firstUnreadMessageId\",\"message-form\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/collaborations/collaboration.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});