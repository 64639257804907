define("creators-web-app/templates/protected/verified/campaigns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "poxJxakI",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"templates.protected.verified.campaigns.page-title\"],null]],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"protected-verified__content-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"protected-verified__content\"],[12],[1,\"\\n    \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"t\",\"component\",\"-outlet\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/campaigns.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});