define("creators-web-app/templates/protected/verified/gigs/gig/prep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "26CBwS/y",
    "block": "[[[10,0],[14,0,\"protected-verified-gigs-gig-prep\"],[12],[1,\"\\n\"],[41,[30,0,[\"displayNav\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@gig\"],[[36,2]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"protected-verified-gigs-gig-prep__outlet\"],[12],[1,\"\\n    \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"gig-page/navigation\",\"model\",\"component\",\"-outlet\"]]",
    "moduleName": "creators-web-app/templates/protected/verified/gigs/gig/prep.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});