define("creators-web-app/lib/infinity-model-cursor-extension-prepend", ["exports", "moment", "ember-infinity/lib/infinity-model"], function (_exports, _moment, _infinityModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _infinityModel.default.extend({
    buildParams: function buildParams() {
      var params = this._super.apply(this, arguments);

      if (params['cursorValueAttr']) {
        Ember.set(this, 'cursorValueAttr', params['cursorValueAttr']);
        delete params.cursorValueAttr;
      }

      if (this.cursor) {
        params['cursor'] = this.cursor;
        delete params.page;
      } else {
        params['cursor'] = _moment.default.utc().format();
      }

      return params;
    },
    afterInfinityModel: function afterInfinityModel(records, infinityModel) {
      var loadedAny = Ember.get(records, 'length') > 0;
      Ember.set(this, 'canLoadMore', loadedAny);

      if (loadedAny) {
        var lastObjectDate = Ember.get(records, "firstObject.".concat(this.cursorValueAttr));
        Ember.set(this, 'cursor', _moment.default.utc(lastObjectDate).format());
      }
    },
    cursorValueAttr: 'createdAt'
  });

  _exports.default = _default;
});