define("creators-web-app/templates/auth/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QBaB28bb",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"meta-tags.login.title\"],null]],null]],[1,\"\\n\\n\"],[8,[39,2],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"auth__form\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"auth__form-title text-align--center\"],[12],[1,[28,[35,1],[\"templates.auth.login.title\"],null]],[13],[1,\"\\n  \"],[10,2],[14,0,\"auth__form-subheader text-align--center\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"templates.auth.login.welcome-message\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,3],null,[[\"@submitText\",\"@onSubmit\"],[[28,[37,1],[\"templates.auth.login.submit-label\"],null],[28,[37,4],[[30,0],\"authenticate\"],null]]],null],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"t\",\"flash-messages\",\"login-form\",\"action\"]]",
    "moduleName": "creators-web-app/templates/auth/login.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});